/*
 * @Author: your name
 * @Date: 2021-08-09 15:32:43
 * @LastEditTime: 2021-08-10 14:13:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pc-wesite\middleware\metaTitle.js
 */

export default ({store, route, redirect}) => {
    route.matched.forEach((item, index) => {
        item.meta.title = route.meta[index].title || '';
    })
}
