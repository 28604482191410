/*
 * @Author: your name
 * @Date: 2021-08-09 10:22:49
 * @LastEditTime: 2021-08-21 21:02:55
 * @LastEditors: yqf
 * @Description: In User Settings Edit
 * @FilePath: \pc-wesite\plugins\element-ui.js
 */

import Vue from 'vue'
import "../theme/index.css"; // 引入主题颜色#D93639
import {
  Link,
  Tooltip,
  Loading,
  Button,
  Alert,
  Popover,
  Carousel,
  CarouselItem,
  Pagination,
  Menu,
  Submenu,
  MenuItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Breadcrumb,
  BreadcrumbItem,
  Input,
  Row,
  Col,
  Table,
  TableColumn
} from 'element-ui' //引入分页组件
Vue.prototype.$ELEMENT = { size: 'small' };
Vue.use(Row);
Vue.use(Col);
Vue.use(Loading);
Vue.use(Alert);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Input);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Link);
Vue.use(Tooltip);
