/*
 * @Description: 
 * @Version: 
 * @Autor: yqf
 * @Date: 2021-08-12 19:56:23
 * @LastEditors: yqf
 * @LastEditTime: 2021-08-12 20:02:50
 * @FilePath: \pc-wesite\plugins\qrCode.js
 */
import vue from 'vue'
import QRCode from 'qrcodejs2';
vue.prototype.QRCode=QRCode
