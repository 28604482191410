/*
 * @Author: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @Date: 2022-05-16 10:26:33
 * @LastEditors: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @LastEditTime: 2022-05-16 11:05:24
 * @FilePath: \pc-wesite\plugins\router-nprogress.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
export default ({ app }) => {
    app.router.beforeEach((to, from, next) => {
        NProgress.start()
        next()
    })

    app.router.afterEach(() => {
        NProgress.done()
    })
}
