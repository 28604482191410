/*
 * @Author: zhangshaojie zhangshaojie@yxj.org.cn
 * @Date: 2024-01-29 17:47:53
 * @LastEditors: zhangshaojie zhangshaojie@yxj.org.cn
 * @LastEditTime: 2022-05-13 14:55:53
 * @FilePath: \pc-wesite\plugins\route-report.js
 * @Description: 路由埋点
 */


import { customYszReport,getEventPvReportData } from "~/utils/common.js";


export default ({
    app
}) => {
    app.router.beforeEach(async (to, from, next) => {
        const reportBody = getEventPvReportData(to)
        next()
        //等待实例加载结束
        setTimeout(()=>{
            customYszReport('event_platform_pv', reportBody)
        },300)
    })
}