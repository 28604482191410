import LRU from 'lru-cache'

let cacheStore = new LRU({
  max: 100,         // 设置最大的缓存个数
  maxAge: 1000 * 120
})

export default function ({ res, route }) {
  if (res && route && route.fullPath) {

    let key = route.fullPath

    // 如果缓存命中，直接返回
    const value = cacheStore.get(key)

    if (value) {
      return res.send(value, 'utf-8')
    } else {
      res.original_end = res.end
      // 重写res.end
      res.send = function (data) {
        if (res.statusCode === 200) {
          // 设置缓存
          cacheStore.set(key, data)
        }
        // 最终返回结果
        res.original_end(data, 'utf-8')
      }
      return res;
    }
  }
  return false
}



