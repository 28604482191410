<template>
  <div class="feedback-top">
    <div class="placeholder" ref="placeholder"></div>
    <div class="feedback-top-wrap" :style="{ right: 0 + 'px' }">
      <!-- 广告位 -->
      <!-- <div class="item">
        <a target="_blank" :href="bottomAd && bottomAd.link">
          <img
            class="bottomAd"
            src="../../assets/images/yxj_home/ad@2x.png"
            alt=""
          />
        </a>
      </div> -->
      <!-- 回到顶部 -->
      <div @click="toTop" class="item item-1 hand">
        <img src="../../assets/images/yxj_home/top.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      feedbackLeft: 10000000,
      bottomAd: null,
    }
  },
  mounted() {
    // this.getBottomAd()
    let self = this
    setTimeout(() => {
      self.pageWidthListener()
    }, 1000);
    this.$nextTick(() => {
      window.addEventListener('resize', self.pageWidthListener, false)
      // self.pageWidthListener()
    })
  },
  props: {
  },
  methods: {
    /** 监听页面宽度 */
    pageWidthListener() {
      this.$nextTick(() => {
        let self = this
        let left = self.$refs.placeholder.offsetLeft
        // console.log(self.$refs.placeholder.offsetLeft)
        self.feedbackLeft = left + 1350
        // console.log(self.feedbackLeft, 'self.feedbackLeft')
      })

    },
    /** 回到顶部 */
    toTop() {
      // console.log('toTop')
      let distance = document.documentElement.scrollTop || document.body.scrollTop // 获得当前高度
      let step = distance / 50 // 每步的距离
      jump()
      function jump() {
        if (distance > 0) {
          distance -= step
          window.scrollTo(0, distance)
        }
        setTimeout(jump, 10)
      }
    },
    /** 意见反馈 */
    feedback() {
      // console.log('feed')
    },
    getBottomAd() {
      // back_top广告
      this.$axios.$post('/ysz-content/web/adverts/queryAdvertsByNode', {
        departmentId: this.$route.query.deptID || 0,
        node1: '小角标位',
      }).then(({ body: res }) => {
        // console.log(res, 'resbot');
        this.bottomAd = res
      })
        .catch(err => {
          console.log(err);
        })
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.pageWidthListener)
  }
}
</script>

<style lang="scss" scoped>
.feedbackLeft {
  width: 100%;
  height: 0;
}
.feedback-top {
  position: fixed;
  width: 100%;
  height: 10px;
  z-index: 99999;
  // background-color: #100;
  // right: 0;
  bottom: 0;
}
.feedback-top-wrap {
  position: fixed;
  bottom: 100px;
  z-index: 99999;
  height: 100px;
}
.placeholder {
  position: relative;
  width: 1200px;
  height: 100px;
  // background-color: red;
  left: 50%;
  margin-left: -600px;
  // margin:  0 auto;
}
.item {
  width: 58px;
  // height: 58px;
  // background-color: #ddd;
  z-index: 10;
  a {
    display: block;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 76px;
    }
  }
}
.item:nth-child(1) {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .feedback-top-wrap {
    display: none;
  }
}
</style>
