/*
 * @Author: your name
 * @Date: 2021-08-20 17:44:42
 * @LastEditTime: 2023-12-27 18:53:05
 * @LastEditors: youqifa youqifa@yxj.org.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc-wesite\global-config.js
 */
export default {
  baseUrl: {
    prod: 'https://pcapi.yxj.org.cn/', // 生产
    sml: 'https://pre.api.yxj.org.cn/', // 模拟
    other: 'https://test.api.yxj.org.cn/' // 开发及测试
    // other: 'https://pcapi.yxj.org.cn/' // 开发及测试
  },
  oldApiUrl: {
    prod: 'https://api.yishengzhan.cn/gw/', // 生产
    sml: 'https://pre.api.yishengzhan.cn/gw/', // 预生产
    other: 'https://test.api.yishengzhan.cn/gw/' // 开发及测试
    // other: 'https://api.yishengzhan.cn/gw/' // 开发及测试
  },
  analyseApiUrl: {
    prod: 'https://analyse.yishengzhan.cn/', // 生产
    other: 'https://test.analyse.yishengzhan.cn/' // 开发及测试
  },
  saUrl: {
    prod: 'https://sensors.yishengzhan.cn/sa?project=production', // 生产
    sml: 'https://sensors.yishengzhan.cn/sa?project=default', // 模拟
    other: 'https://sensors.yishengzhan.cn/sa?project=default' // 开发及测试
  },
  oldBaseUrl: { // 跳转
    prod: 'https://web.yishengzhan.cn/',
    sml: 'https://web.yishengzhan.cn/',
    other: 'https://web.yishengzhan.cn/',
  },
  mobileUrl: {
    prod: 'https://ysz-web.yxj.org.cn/live/dist/index.html?ps=2#',
    sml: 'https://pre-web.yxj.org.cn/live/dist/index.html?ps=2#',
    test: 'https://test-web.yxj.org.cn/live/dist/index.html?ps=2#'
  },
  mobileClinicalUrl: {
    prod: 'https://ysz-web.yxj.org.cn/ysz-clinical/index.html#/?id=',
    test: 'https://test-web.yxj.org.cn/ysz-clinical/index.html#/?id='
  },
  // sdkUrl: 'https://asset.tsign.cn/tsign-sdk/tsigndata-sdk-js/v1.0.7/tsigndata-sdk-js.js',
  routerBaseOpt: ''
}