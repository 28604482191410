/*
 * @Author: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @Date: 2021-08-20 17:47:53
 * @LastEditors: zhouxiaobo zhouxiaobo@yxj.org.cn
 * @LastEditTime: 2022-05-13 14:55:53
 * @FilePath: \pc-wesite\plugins\route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import store from "vuex";
export default ({
  app
}) => {
  app.router.beforeEach(async (to, from, next) => {
    console.log('to--------------', to, '\nfrom--------------------------', from)
    // console.log(111111111111111111,store)
    // console.log('to：', to);
    // console.log('from', from);
    // if (from.query.deptID) {
    //   let newQuery = JSON.parse(JSON.stringify(from.query));
    //   console.log(newQuery)
    //   newQuery.deptID = from.query.deptID
    //   console.log(newQuery)
    //   console.log('有id,并且id不同')
    //   if (to.query.deptID == from.query.deptID) {
    //     next()
    //   } else {
    //     next({ path: to.path, query: newQuery })
    //   }
    // } else {
    //   next()
    // }
    if (to.query.change) {
      next()
      return
    }
    next()
  })
}