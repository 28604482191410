import Vue from "vue";
import {
  Search,
  List,
  PullRefresh,
  Popup,
  NavBar,
  IndexBar,
  IndexAnchor,
  Collapse,
  CollapseItem,
  Cell
} from "vant";
import "vant/lib/index.css";



Vue.use(Search);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(NavBar);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cell);

