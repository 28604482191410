/*
 * @Description: 
 * @Version: 
 * @Autor: yqf
 * @Date: 2022-09-28 19:48:43
 * @LastEditors: yqf
 * @LastEditTime: 2022-09-28 20:01:12
 * @FilePath: \h5-lcjc-drugsDetail\src\utils\funny.js
 */
module.exports = function () {
  try {
    setInterval(() => {
      Function("debugger")();
    }, 50);

    document.addEventListener('selectstart', function (e) {
      e.preventDefault && e.preventDefault();
      return false
    });
    document.addEventListener('contextmenu', function (e) {
      e.preventDefault && e.preventDefault();
      return false
    });
    document.addEventListener('keydown', onkeydownStop);
  } catch (error) {
    console.log('ggggggg', error);
  }

}

function onkeydownStop() {
  var e = process.browser.event || arguments[0];
  console.log('onkeydown_e', e);
  if (e.keyCode == 123) {
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    return false;
  }
  if ((e.ctrlKey) && (e.keyCode == 83)) { //ctrl+s
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    return false;
  }
  if ((e.ctrlKey) && (e.keyCode == 85)) { //ctrl+u
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    return false;
  }
  if ((e.ctrlKey) && (e.keyCode == 73)) { //ctrl+shift+i
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    return false;
  }
}