import axios from 'axios'
import LRU from 'lru-cache'
import Encode from '../utils/encode.js'

import cookies from 'js-cookie'
// axios.defaults.withCredentials = true
const CACHED = new LRU({
    max: 1000, // 缓存队列长度
    maxAge: 1000 * 120, // 缓存时间
})

//解析浏览器中的cookies

function getCookie(name, strCookie) {
    if (!name || !strCookie) {
        return ''
    }
    // console.log('strCookie', strCookie)

    var arrCookie = strCookie.split(';')

    for (var i = 0; i < arrCookie.length; i++) {
        var arr = arrCookie[i].split('=')

        if (arr[0].trim() === name) {
            return arr[1]
        }
    }

    return null
}
import globalConfig from '../global-config'
// import cookies from 'js-cookie'
var key
export default function({ $axios, redirect }) {
    // $axios = axios
    // let cancelToken = $axios.CancelToken;
    // $axios.onRequest(config => {
    //   var baseURL =
    //     process.env.environment === 'prod'
    //       ? globalConfig.baseUrl.prod
    //       : process.env.environment === 'sml'
    //       ? globalConfig.baseUrl.sml
    //       : globalConfig.baseUrl.other
    //   config.baseURL = baseURL

    //     if(config.url === '/api/cate/list/1') {
    //       console.log(1112222)
    //       console.log($axios)
    //       config.cancelToken = new cancelToken((c) => {
    //         console.log(3)
    //         c(123)
    //       });
    //       console.log(222222,config)
    //     }
    // })

    // $axios.onError(error => {
    //   console.log(111 ,error)
    //   const code = parseInt(error.response && error.response.status)
    //   if (code === 400) {
    //     redirect('/400')
    //   }
    // })
    // let cancelToken = axios.CancelToken;
    // var key
    $axios.interceptors.request.use(
        (config) => {
          console.log('request_config',config);
          if(config.url.indexOf('analyse.yishengzhan.cn') > -1){
            return config
          }
            // console.log(process.env.baseURL)
            var baseURL =
                process.env.environment === 'prod'
                    ? globalConfig.baseUrl.prod
                    : process.env.environment === 'sml'
                    ? globalConfig.baseUrl.sml
                    : globalConfig.baseUrl.other
            config.baseURL = baseURL
            console.log('请求地址：', config.url + Date.now())
            // console.log('请求参数：', config.data)
            // key = JSON.stringify(config.url) + JSON.stringify(config.data) + JSON.stringify(config.params) + JSON.stringify(config.headers)
            // console.log(999,config.url)
            // 如果当前接口存在缓存并且不是通过全地址调用的  就取缓存的数据  并阻止当前请求发送到服务器
            // if (CACHED.has(key) && config.url.indexOf('://') === -1) {
            //   console.log('缓存命中',config.url)
            //   config.cancelToken = new cancelToken((c) => {
            //     // 缓存命中，阻止当前请求  并将缓存的数据返回    在错误中接收
            //     c({cache:CACHED.get(key)})
            //   });
            // }
            // 参数加密
            // if (process.env.environment === 'prod') {
            // console.log(123,config.url.indexOf('ysz-auth/oauth/token') > -1)
            
            const idOldApi = config.url.indexOf('api.yishengzhan.cn/gw/') > -1
            if (
                config.method === 'post' &&
                config.url.indexOf('ysz-auth/oauth/token') == -1
            ) {
                config.data = Encode.encodeFunc(config.data)
                config.headers = idOldApi
                    ? { ...config.headers }
                    : { ...config.headers, debug: 1 }
            }
            // console.log(config.data)
            // }
            // console.log('加密入参：', config.data)
            // 设置token请求头
            // console.log('token----', config.headers.common.cookie)
            if (!idOldApi) {
                var token =
                    getCookie('token', config.headers.common.cookie) ||
                    cookies.get('token')
                // console.log('获取到的token--------', token)
                // 将获取的token放入请求头
                if (token) {
                    config.headers.token = token
                }
                // if (cookies.get('token')) {
                //   config.headers.token = cookies.get('token')
                // }
                // console.log(123, document)
                // if (document === undefined) {
                //   console.log(123456)
                // }
                config.headers.Cookies = config.headers.common.cookie || ''
                try {
                    config.headers.Cookies = document.cookie
                } catch (error) {
                    // console.log(111, error)
                }
            }
            const newConfig = { ...config }
            newConfig.metadata = { startTime: new Date() }
            return newConfig
            // return config
        },
        (err) => {
            console.log('请求发送报错：', err)
            return Promise.reject(err)
        }
    )
    //响应拦截器
    $axios.interceptors.response.use(
        (response) => {
            console.log('响应出参：' + response.config.url, response)
            if(response.config.url.indexOf('analyse.yishengzhan.cn') > -1){
              return {...response}
            }
            // 参数解密
            // if (process.env.environment === 'prod') {
            if (
                response.config.method === 'post' &&
                response.config.url.indexOf('ysz-auth/oauth/token') == -1
            ) {
                // response.data = Encode.encodeFunc(response.data)
                response.data = JSON.parse(Encode.decodeFuc(response.data.data))
            }
            // console.log('响应解密：', response.config.url, response.data)
            // }
            const newRes = { ...response }
            newRes.config.metadata.endTime = new Date()
            newRes.duration =
                newRes.config.metadata.endTime -
                newRes.config.metadata.startTime
            console.log(
                '响应地址：',
                response.config.url +
                    ' time:' +
                    Date.now() +
                    ' 耗时： ' +
                    newRes.duration
            )
            return newRes
            // return response;
        },
        (error) => {
            // console.log(error)
            console.log('接口报错：', error)
            // 判断当前错误对象中是否含有缓存关键字
            if (error.message.cache) {
                // 有，代表当前数据是缓存数据 正常输出给客户端
                return error.message.cache // 返回接口返回的错误信息（这里返回空对象是为了避免控制台报错）
            } else {
                // 不是缓存数据，按正常错误流程走
                const code = parseInt(error.response && error.response.status)
                console.log('接口报错：', error.response)
                if (code === 400) {
                    redirect('/400')
                }
                if (code === 401) {
                  return error.response
                }
                
            }
        }
    )
}
