/*
 * @Description: 获取页面埋点上报的type和typeId
 * @Version:
 * @Autor: 张韶杰
 * @Date: 2024-02-29 15:49:02
 * @LastEditors: 张韶杰
 * @LastEditTime: 2024-02-29 15:49:02
 */
/* eslint-disable */

//
const typeMap = {
  "/guide/details": {
    type: "guidePDF",
    typeIdKey: "id",
  },
};

export default function(to) {
  let pageType = {
    evtPropsTitle: null,
    evtPropsType: null,
    evtPropsTypeId: null,
  };
  const typeItem = typeMap[to.path];
  if (typeItem) {
    pageType.evtPropsTitle = document.title?document.title: null;
    pageType.evtPropsType = typeItem.type;
    pageType.evtPropsTypeId = to.query[typeItem.typeIdKey]?to.query[typeItem.typeIdKey]: null;
  }
  return pageType
}
