<template>
  <div class="footer">
    <footer>
      <div class="container">
        <div class="footer-link">
          <!-- 关于医学界 -->
          <div class="about-us">
            <div class="about-yxj">
              <div class="about-title">关于医学界</div>
              <ul>
                <li>
                  <!-- <nuxt-link target="_blank" class="text" 
                    >关于我们</nuxt-link
                  > -->
                  <a href="https://group.yxj.org.cn/" rel="nofollow"
                    >关于我们</a
                  >
                </li>
                <li>
                  <a href="https://group.yxj.org.cn/contact" rel="nofollow"
                    >联系我们</a
                  >
                  <!-- <nuxt-link target="_blank" class="text" to="/about/contact"
                    >联系我们</nuxt-link
                  > -->
                </li>
                <li>
                  <a @click="jumpLink('/about/terms')" rel="nofollow">使用条款</a>
                  <!-- <nuxt-link class="text" to="/about/terms"
                    >使用条款</nuxt-link
                  > -->
                </li>
                <li>
                  <a @click="jumpLink('/about/privacy')" rel="nofollow">隐私政策</a>
                  <!-- <nuxt-link class="text" to="/about/privacy"
                    >隐私政策</nuxt-link
                  > -->
                </li>
              </ul>
            </div>
          </div>
          <!-- 微信公众号 -->
          <div class="links">
            <div class="wechat-title">微信公众号</div>
            <div class="wechat-yxj">
              <ul>
                <li v-for="(item, index) in wechatLinks" :key="index">
                  <!-- <el-popover
                    :visible-arrow="false"
                    placement="top"
                    trigger="hover"
                    popper-class="wechat_qrcode"
                  > -->
                  <div class="hover_btn" slot="reference">
                    {{ item.title }}
                  </div>
                  <!-- </el-popover> -->
                </li>
              </ul>
            </div>
            <!-- <div
              class="link-item"
              v-for="(item, index) in proList"
              :key="index"
              :class="{
                active: showProduct,
              }"
            >
              <div class="title" @click="showProduct = !showProduct">
                {{ item.data.title }}
              </div>
              <ul>
                <li v-for="(n, i) in item.children" :key="i">
                  <nuxt-link target="_blank"
                    v-if="
                      n[0].url.indexOf('http') == -1 &&
                      n[0].url.indexOf('mailto:') == -1 &&
                      n[0].url.indexOf('tel:') == -1
                    "
                    :to="n[0].url"
                    >{{ n[0].title }}</nuxt-link
                  >
                  <a
                    v-else
                    href="javascript:;"
                    @click="toHref(n[0].url)"
                    :title="n[0].url | getTit"
                    >{{ n[0].title }}</a
                  >
                </li>
              </ul>
            </div> -->
          </div>
          <!-- 关注更多 -->
          <div class="about-more">
            <div class="more-yxj">
              <div class="more-title">关注更多</div>
              <div class="link-item">
                <div class="qr fr clear">
                  <div class="btns">
                    <div
                      title="手机端"
                      data-index="0"
                      @mouseenter="ewmI = 0"
                      @mouseleave="ewmI = -1"
                      :class="ewmI == 0 ? 'btn active' : 'btn'"
                    >
                      <img src="../assets/images/yxj_home/mobile.png" alt="" />
                      <img
                        src="../assets/images/yxj_home/phone_active@2x.png"
                        alt=""
                      />
                      <span class="qr-title">手机端</span>
                      <div
                        class="phone_img"
                        :class="ewmI == 0 ? 'phone_img_active' : ''"
                      >
                        <p>医学界医生站</p>
                        <div class="img">
                          <img src="../assets/images/yxj_home/sj.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      title="关注微博"
                      data-index="1"
                      @mouseenter="ewmI = 1"
                      @mouseleave="ewmI = -1"
                      :class="ewmI == 1 ? 'btn active' : 'btn'"
                    >
                      <img src="../assets/images/yxj_home/weibo.png" alt="" />
                      <img
                        src="../assets/images/yxj_home/weibo_active@2x.png"
                        alt=""
                      />
                      <span class="qr-title">关注微博</span>
                      <div
                        class="weibo_img"
                        :class="ewmI == 1 ? 'weibo_img_active' : ''"
                      >
                        <p>扫码关注微博</p>
                        <div class="img">
                          <img src="../assets/images/yxj_home/wb.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      title="关注知乎"
                      data-index="2"
                      @mouseenter="ewmI = 2"
                      @mouseleave="ewmI = -1"
                      :class="ewmI == 2 ? 'btn active' : 'btn'"
                    >
                      <img
                        src="../assets/images/yxj_home/konwldge.png"
                        alt=""
                      />
                      <img
                        src="../assets/images/yxj_home/know_active@2x.png"
                        alt=""
                      />
                      <span class="qr-title">关注知乎</span>
                      <div
                        class="zhihu_img"
                        :class="ewmI == 2 ? 'zhihu_img_active' : ''"
                      >
                        <p>扫码关注知乎</p>
                        <div class="img">
                          <img src="../assets/images/yxj_home/zh.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="qr-imgs">
                    <div :class="ewmI == 0 ? 'qr-img active' : 'qr-img'">
                      <img v-if="pz.r1" v-lazy="pz.r1.logo" alt="" />
                    </div>
                    <div :class="ewmI == 1 ? 'qr-img active' : 'qr-img'">
                      <img v-if="pz.r2" v-lazy="pz.r2.logo" alt="" />
                    </div>
                    <div :class="ewmI == 2 ? 'qr-img active' : 'qr-img'">
                      <img v-if="pz.r3" v-lazy="pz.r3.logo" alt="" />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- 版权声明 -->
    <div class="bottom">
      <div class="container">
        <div class="copyright">
          <p>
            <span>©2010-2021 上海医米信息技术有限公司</span>
          </p>
          <p>
            <span>医学界 （沪）-经营性-2021-0169 |<span class="canLink" @click="openIcp"> 沪ICP备 12014230号-1</span> </span>
          </p>
          <p>
            <span
              >信息系统安全保护等级备案证明 31011013186-18001 |
              增值电信业务经营许可证 沪B2-20170033</span
            >
          </p>
        </div>
      </div>
    </div>

    <Dialog
      :title="xy.title"
      :visible.sync="showXy"
      width="90%"
      top="80px"
      class="footer-dialog"
    >
      <no-ssr>
        <div v-html="xy.content"></div>
      </no-ssr>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui'
export default {
  name: 'common',
  serverCacheKey() {
    return 'footer'
  },
  components: {
    Dialog
  },
  data() {
    return {
      xy1: {},
      xy2: {},
      xy3: {},
      xy: {},
      showXy: false,
      friend: [],
      showProduct: false,
      showQuick: false,
      showAbout: false,
      showCooperation: false,
      ewmI: -1,
      proList: [],
      pz: {},
      aboutLinks: [
        {
          name: '关于我们',
          links: 'introduction'
        },
        {
          name: '联系我们',
          links: 'introduction'
        },
        {
          name: '使用条款',
          links: 'introduction'
        },
        {
          name: '隐私政策',
          links: 'introduction'
        },
        // {
        //   name: '常见问题',
        //   links: 'introduction'
        // }
      ], // 关于医学界
      wechatLinks: [
        {
          title: '医学界',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界智库',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界基层医声',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界肿瘤频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界风湿免疫频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界皮肤频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界心血管频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界内分泌频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界神经病学频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界血液频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界儿科频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界妇产科频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界呼吸频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界肾病频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界消化肝病频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界临床药学频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界罕见病频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界癌友会',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界急诊与重症频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界精神频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界外科频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界护理频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界眼科频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界感染频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界麻醉频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界骨科频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界检验频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界影像诊断与介入频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界病理频道',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '金陵肺癌网络论坛',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界罕见靶点生态圈',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '临床决策助手',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界医生站',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界精品课',
          links: 'http://web.yishengzhan.cn/#/home'
        },
        {
          title: '医学界医考',
          links: 'http://web.yishengzhan.cn/#/home'
        }
      ], // 微信公众号
    }
  },
  filters: {
    getTit(url) {
      if (url.indexOf('mailto:') >= 0) {
        return url.split('mailto:')[1]
      } else if (url.indexOf('tel:') >= 0) {
        return url.split('tel:')[1]
      } else {
        return ''
      }
    }
  },
  methods: {
    openIcp() {
      window.open('https://beian.miit.gov.cn','_blank')
    },
    goaboutme() {
      window.open('https://group.yxj.org.cn/')
    },
    jumpLink(path) {
      if (path === this.$route.path) {
        location.reload()
        return
      }
      this.$router.push(path, false)
    },
    toHref(url) {
      window.localStorage.removeItem('esign_version')
      window.location.href = url
    },
    async getXy1() {
      try {
        var r1 = await this.$axios.$get(`/api/config/show/10009`)
        var r2 = await this.$axios.$get(`/api/config/show/10010`)
        var r3 = await this.$axios.$get(`/api/config/show/10011`)
        this.xy1 = r1.data
        this.xy2 = r2.data
        this.xy3 = r3.data
      } catch (error) {
        console.log(error)
      }
    },
    async getFriend() {
      try {
        var res = await this.$axios.get('/api/friendship_link/list')
        this.friend = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async getPro() {
      try {
        var res = await this.$axios.$get('/api/cate/list/2')
        this.proList = res.data
      } catch (error) {
        console.log(error)
      }
    },
    async getPz() {

      try {
        var res = await this.$axios.$get('/api/cate/list/2')
        var r1 = await this.$axios.$get(`/api/config/show/10002`)
        var r2 = await this.$axios.$get(`/api/config/show/10003`)
        var r3 = await this.$axios.$get(`/api/config/show/10004`)
        var r4 = await this.$axios.$get(`/api/config/show/10005`)
        var r5 = await this.$axios.$get(`/api/config/show/10006`)
        var r6 = await this.$axios.$get(`/api/config/show/10007`)
        var r7 = await this.$axios.$get(`/api/config/show/10008`)
        this.pz = {
          r1: r1.data,
          r2: r2.data,
          r3: r3.data,
          r4: r4.data,
          r5: r5.data,
          r6: r6.data,
          r7: r7.data
        }
      } catch (error) {
        console.log(error)
      }


    }
  }
}
</script>

<style lang='scss' scoped>
.footer {
  background-color: #fff;
  color: #242424;
  border-top: 4px solid #d70c19;

  footer {
    .footer-link {
      height: 288px;
      display: flex;
      justify-content: space-between;
      padding-top: 27px;
    }
    .about-us {
      width: 178px;
      display: flex;
      justify-content: space-between;
      position: relative;
      .about-yxj {
        width: 100%;
        padding-right: 40px;
        .about-title {
          width: 100%;
          height: 28px;
          font-size: 16px;

          font-weight: 600;
          color: #333;
          line-height: 28px;
          margin-bottom: 18px;
        }
        ul {
          .text:hover {
            color: #d70c19;
          }
          li:nth-child(2n + 1) {
            margin-right: 25px;
          }
          li {
            float: left;
            // width: 48px;
            height: 20px;
            line-height: 20px;
            // margin-right: 38px;
            margin-bottom: 10px;
            a {
              font-size: 14px;
              font-weight: 400;
              color: #666;
              &:hover {
                color: #d70c19;
              }
            }
          }
        }
      }
    }
    .links {
      width: 780px;
      height: 306px;
      position: relative;
      padding: 0 40px;
      border-left: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      .wechat-title {
        width: 100%;
        height: 28px;
        font-size: 16px;

        font-weight: 600;
        color: #242424;
        line-height: 28px;
        margin-bottom: 18px;
      }
      .wechat-yxj {
        ul {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          // min-width: 802px;
          li {
            // padding-le: ;
            // float: left;
            height: 20px;
            line-height: 20px;
            margin: 0 49px 10px 0;
            &:nth-child(4n + 1) {
              width: 126px;
            }
            &:nth-child(4n + 2) {
              width: 112px;
            }
            &:nth-child(4n + 3) {
              width: 140px;
            }
            &:nth-child(4n + 4) {
              width: 168px;
              margin-right: 0;
            }
            &:nth-last-child(-n + 3) {
              margin-bottom: 0;
            }
            div {
              font-size: 14px;
              font-weight: 400;
              color: #666;
            }
          }
          span:hover {
            color: #d70c19;
          }
        }
      }
    }
    .about-more {
      width: 346px;
      padding-left: 50px;
      .more-yxj {
        width: 100%;
        .more-title {
          height: 22px;
          font-size: 16px;
          font-weight: 600;
          color: #333;
          line-height: 22px;
          margin-bottom: 21px;
        }
      }
    }

    .link-item {
      .qr {
        width: 100%;
        .btns {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          margin-bottom: 17px;

          .btn {
            // overflow: hidden;
            cursor: pointer;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .phone_img {
              display: none;
              position: absolute;
              z-index: 10;
              width: 120px;
              height: 153px;
              background-color: #fff;
              top: -170px;
              box-shadow: 0px 2px 4px 0px rgba(255, 239, 239, 0.5);
              border: 1px solid #fff3f3;
              p {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin: 14px 0 11px;
              }
              .img {
                width: 81px;
                height: 81px;
                // background: #d8d8d8;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &.phone_img_active {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: center;
                transition: all 0.2s ease-in-out;
              }
              &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                border: 7px solid transparent;
                border-bottom: 0;
                border-top: 9px solid #fff3f3;
              }
            }
            .weibo_img {
              display: none;
              position: absolute;
              z-index: 10;
              width: 120px;
              height: 153px;
              background-color: #fff;
              top: -170px;
              box-shadow: 0px 2px 4px 0px rgba(255, 239, 239, 0.5);
              border: 1px solid #fff3f3;
              p {
                height: 20px;
                font-size: 14px;

                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin: 14px 0 11px;
              }
              .img {
                width: 81px;
                height: 81px;
                // background: #d8d8d8;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &.weibo_img_active {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: center;
                transition: all 0.2s ease-in-out;
              }
              &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                border: 7px solid transparent;
                border-bottom: 0;
                border-top: 9px solid #fff3f3;
              }
            }
            .zhihu_img {
              display: none;
              position: absolute;
              z-index: 10;
              width: 120px;
              height: 153px;
              background-color: #fff;
              top: -170px;
              box-shadow: 0px 2px 4px 0px rgba(255, 239, 239, 0.5);
              border: 1px solid #fff3f3;
              p {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
                margin: 14px 0 11px;
              }
              .img {
                width: 81px;
                height: 81px;
                // background: #d8d8d8;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &.zhihu_img_active {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                align-items: center;
                transition: all 0.2s ease-in-out;
              }
              &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                border: 7px solid transparent;
                border-bottom: 0;
                border-top: 9px solid #fff3f3;
              }
            }
            img {
              width: 60px;
              visibility: visible;
              opacity: 1;
              transition: all 0.2s ease-in-out;

              &:nth-child(2) {
                position: absolute;
                top: 0;
                visibility: hidden;
                opacity: 0;
              }
            }

            .qr-title {
              height: 24px;
              font-size: 16px;
              font-weight: 400;
              color: #4a4a4a;
              line-height: 24px;
              margin-top: 13px;
            }

            &.active {
              img {
                // visibility: hidden;
                // opacity: 0;

                &:nth-child(2) {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .qr-imgs {
        position: absolute;

        .qr-img {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s ease-in-out;

          &.active {
            visibility: visible;
            opacity: 1;
          }

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: -8px;
            border: 7px solid transparent;
            border-top: 0;
            border-bottom: 8px solid #ddd;
          }

          &:nth-child(1) {
            position: relative;
          }

          &:nth-child(1)::before {
            left: 8px;
          }

          &:nth-child(2)::before {
            left: 0;
            right: 0;
            margin: auto;
          }

          &:nth-child(3)::before {
            right: 8px;
          }
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 226px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: center;
    color: #666;
    font-size: 12px;
    line-height: 32px;
    padding-top: 135px;
    .copyright {
      height: 64px;
      font-size: 12px;

      font-weight: 500;
      color: #9a9a9a;
      line-height: 20px;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        .canLink {
          cursor: pointer;
        }
      }
    }
    a {
      color: #666;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #fff;
      }
    }

    span {
      word-break: keep-all;
    }
  }
}
.clear::after {
  content: "";
  display: block;
  clear: both;
}
li {
  clear: none !important;
}
@media only screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
</style>