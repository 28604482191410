
import globalConfig from '../global-config'


export async function connectionSocket() {
  let that = window.$nuxt // 获取vue实例
  that.$store.commit('initSock')
}
// function connect() {
//   console.log('globalConfig', globalConfig)
//   let socket = new SockJS(baseURL + 'ysz-websocket/ws')
//   stompClient = Stomp.over(socket)
//   let that = window.$nuxt // 获取vue实例
//   let userInfo = {} // 获取药店信息
//   let headers = {
//     pharmacyId: userInfo.shopId || 123456
//   }
//   var timer
//   socket.onclose = () => {
//     connect()
//   }
//   stompClient.connect(headers, () => {
//     console.log('that', stompClient)
//     that.$store.commit('setStompClient', stompClient)
//     console.log('subscriptions', stompClient.subscriptions)
//     // that.prototype.stompClient = stompClient
//     // stompClient.subscribe('/qrcode/123', (msg) => {
//     //   console.log('收到的信息', msg)
//     // }, headers)
//     // setInterval(() => {
//     //   stompClient.send('/fuck', { a: 1 }, 'fuck   everywhere')
//     // }, 2000);
//   }, (err) => {
//     console.log('失败')
//     console.log(err)
//     // 链接失败，重新链接
//     if (reconnect_count < MAX_RECONNECT_COUNT) { // 判断当前重连次数是否小于最大重连次数
//       connect()
//       reconnect_count++
//     } else {
//       console.log('超过最大重连次数，不再重连')
//     }
//   })
// }