import Vue from 'vue'
import vuex from 'vuex'
import sock from './modules/sock'
Vue.use(vuex)

/* eslint-disable */
const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  let moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  moduleName.indexOf('/') !== -1 ? moduleName = moduleName.split('/')[1] : ''
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
export default () => new vuex.Store({

  modules,
  state: {
    maskShow: false, // 遮罩层显示
    typeListFlag: false, // 频道列表显示
    department: null, // 频道-这里默认的应该为今日医学
    isLogin: false,
    stompClient: null
  },
  getters: {
    // stompClient(state) {
    //   return state.stompClient
    // },
    isLogin(state) {
      return state.isLogin
    },
    maskShow(state) {
      return state.maskShow
    },
    department(state) {
      return state.department
    }
  },
  mutations: {
    setStompClient(state, stompClient) {
      state.stompClient = stompClient
    },
    isLogin(state, flag) {
      state.isLogin = flag
    },
    traggleMask(state, flag) {
      state.maskShow = flag
    },
    closeTypeList(state) {
      state.typeListFlag = false
    },
    closeMask(state) {
      state.maskShow = false
    },
    setepartment(state, department) {
      state.department = department
    },
  }
})
