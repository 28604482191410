<template>
  <div class="mask" @click="close"></div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(['closeTypeList', 'closeMask']),
    close() {
      this.closeMask()
      this.closeTypeList()
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  background: #fff;
  opacity: 0.9;
  position: absolute;
  top: 221px;
  left: 0;
  right: 0;
  bottom: 518px;
  z-index: 8;
}
</style>