/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-19 10:31:23
 * @LastEditTime: 2019-09-19 17:57:43
 * @LastEditors: Please set LastEditors
 */
// import {api} from '../../utils/api'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
// import Vue from "Vue";
import globalConfig from '../../global-config'

export const strict = true
// 最大重连次数
const MAX_RECONNECT_COUNT = 5
var reconnect_count = 0
var baseURL =
  process.env.environment === 'prod'
    ? globalConfig.baseUrl.prod
    : process.env.environment === 'sml'
      ? globalConfig.baseUrl.sml
      : globalConfig.baseUrl.other
export default {
  state: {
    a: 1,
    stompClient: null
  },
  getters: {
    stompClient: state => { return state.stompClient }
  },
  mutations: {
    setStompClient() {

    },
    initSock(state, commit) {
      let that = window.$nuxt // 获取vue实例
      var stompClient
      console.log('globalConfig', globalConfig)
      let socket = new SockJS(baseURL + 'ysz-websocket/ws')
      stompClient = Stomp.over(socket)
      let userInfo = {} // 获取药店信息
      let headers = {
        pharmacyId: userInfo.shopId || 123456
      }
      var timer
      socket.onclose = () => {
        that.$store.commit('initSock')
      }
      stompClient.connect(headers, () => {
        state.stompClient = stompClient
      }, (err) => {
        console.log('失败')
        console.log(err)
        // 链接失败，重新链接
        if (reconnect_count < MAX_RECONNECT_COUNT) { // 判断当前重连次数是否小于最大重连次数

          that.$store.commit('initSock')
          reconnect_count++
        } else {
          console.log('超过最大重连次数，不再重连')
        }
      })

      // that.$store.commit('initSock')
    }
  },
  actions: {
  }
}
