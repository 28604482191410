/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-19 10:31:23
 * @LastEditTime: 2023-12-28 10:16:23
 * @LastEditors: youqifa youqifa@yxj.org.cn
 */
import CryptoJS from "crypto-js/crypto-js";

const KEY = CryptoJS.enc.Utf8.parse(2015020120200131);
const KEY2 = CryptoJS.enc.Utf8.parse('1o6CxCSEeeuypW0naBRFO8jJnRCWRrqd');

const encode = {
  encodeFunc(obj,isReportKey) {
    // obj = JSON.stringify(obj)
    console.log(obj);
    let eObj = null;
    if (typeof obj === "string") {
      eObj = obj;
    } else {
      eObj = JSON.stringify(obj);
    }
    const key = isReportKey ? KEY2:KEY;
    const enCodeAes = CryptoJS.AES.encrypt(eObj, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    // let enCodeMd5 = Md5(JSON.stringify(obj))
    const enCodeMd5 = CryptoJS.MD5(eObj).toString();
    const sendData = {
      data: enCodeAes,
      md5: enCodeMd5
    };
    // console.log(sendData);
    return sendData;
  },
  decodeFuc(obj,isReportKey) {
    // console.log(obj);
    const key = isReportKey ? KEY2:KEY;
    // obj = CryptoJS.enc.Base64.stringify(obj);
    const decodeAes = CryptoJS.AES.decrypt(obj, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decodeAes;
  },
  decodeWidthKeyFuc(obj, key) {
    if (!key) {
      return
    }
    const keyNum=Number(key)
    const thirdKEY = CryptoJS.enc.Utf8.parse(keyNum)
    const decodeAes = CryptoJS.AES.decrypt(obj, thirdKEY, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decodeAes;
  },
  encodeStr(str) {
    // obj = JSON.stringify(obj)
    console.log(str);
    const key = KEY;

    const enCodeAes = CryptoJS.AES.encrypt(str, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    console.log(enCodeAes);
    return enCodeAes;
  }
};
export default encode;
