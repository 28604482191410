/*
 * @Author: youqifa youqifa@yxj.org.cn
 * @Date: 2023-12-26 17:38:12
 * @LastEditors: youqifa youqifa@yxj.org.cn
 * @LastEditTime: 2023-12-28 11:34:25
 * @FilePath: \pc-wesite\utils\common.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import encode from "./encode";
import getPageType from "./pageType.js";

export function getCommonUuid() {
  let uuid = localStorage.getItem("uuid");
  if (!uuid) {
    let d = new Date().getTime();
    uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    uuid && localStorage.setItem("uuid", uuid);
  }
  return uuid;
}
export function getEventPvReportData(to) {
  const firstPageFlag = sessionStorage.getItem("firstPageFlag") ? 0 : 1;
  sessionStorage.setItem("firstPageFlag", 1);
  let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const pageType = to?getPageType(to):null;
  const reportBody = {
    uid: loginInfo && loginInfo.user_info ? loginInfo.user_info.uid : 0,
    pagePath: to.path,
    operatingSystem: getOS(),
    // browserTyp: getBrowser(),
    browseSourceUrl: document.referrer ? document.referrer : null,
    landingPage: firstPageFlag ? 1 : 0,
    ...pageType,
  };
  return reportBody
}
export function customYszReport(eventName, paramsReport) {
  if (!window.$nuxt || !window.$nuxt.$axios) {
    console.log("实例未加载");
    return;
  }
  console.log(eventName, paramsReport);
  try {
    let $axios = window.$nuxt.$axios;
    let data = { list: [{ body: { ...paramsReport }, eventName: eventName }] };
    $axios({
      method: "post",
      url: process.env.analyseApiUrl + "api/report",
      // data,
      data: encode.encodeFunc(data, true),
      headers: {
        platform: "PC",
        version: "7.10.1", // pc无法获取app版本，写一个默认的防止接口报非法请求错误
        // 'ip':"''",
        guid: getCommonUuid(),
        sysVersion: "''",
        time: parseInt(+new Date() / 1000),
        // 'isSwagger':"true"
      },
    }).then((res) => {
      console.log("customYszReport_res", res);
      if (res.data && res.data.data) {
        let resData = JSON.parse(encode.decodeFuc(res.data.data, true));
        console.log("resData", resData);
      }

      // this.page.currPage = 1;
      // this.commentList = []
      // this.getComment(this.articleId)
    });
  } catch (error) {
    console.log("customYszReport_error", error);
  }
}

//获取客户端操作系统信息
export function getOS() {
  var userAgent = navigator.userAgent;

  // 判断移动设备操作系统
  if (/Android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  }
  if (/Windows Phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/BlackBerry/i.test(userAgent)) {
    return "BlackBerry";
  }
  if (/Windows NT/i.test(userAgent)) {
    return "Windows";
  }

  // 判断桌面设备操作系统
  if (/Macintosh|Mac OS X/i.test(userAgent)) {
    return "Mac OS";
  }
  if (/Windows/i.test(userAgent)) {
    return "Windows";
  }
  if (/Linux/i.test(userAgent)) {
    return "Linux";
  }

  return "未知";
}
