<template>
  <div class="container err">
    <div v-if="error.statusCode === 404">
      <img src="../assets/images/error/404.svg" alt="" />
      <h4>抱歉你访问的页面不存在！</h4>
    </div>
    <div v-else>
      <h4>应用发生错误异常！</h4>
    </div>
    <div class="banner-btns">
      <nuxt-link target="_blank" to="/" class="free"> 返回首页 </nuxt-link>
      <a href="javascript:;" @click="$router.back()"> 返回上一页 </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],

}
</script>
<style scoped lang="scss">
.err {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 140px 0;
}
img {
  max-width: 436px;
  width: 80%;
  margin: 0px auto 50px;
}
h4 {
  text-align: center;
  font-weight: normal;
  color: #3e3e3f;
  font-size: 24px;
}
.banner-btns {
  text-align: center;
  margin-top: 50px;
  a {
    width: 120px;
    height: 40px;
    line-height: 40px;
    color: #3e3e3f;
    border: 1px solid #3e3e3f;
    background: transparent;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    vertical-align: middle;

    &:first-child {
      color: #fff;
      background: #e61717;
      border: 0;
    }

    &:hover {
      color: #fff;
      -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
      transform: translateY(-4px);
      box-shadow: 0 4px 12px rgba(50, 10, 10, 0.2);
      background: rgba(230, 23, 23, 1);
      border-color: rgba(230, 23, 23, 1);
    }
  }
}
</style>
